<template>
    <div>
        <Header/>
        <Breadcrumbs title="Connexion"/>
        <section class="login-page section-b-space">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6" v-if="needPayment">
                        <h3>Paiement Temps d'acces</h3>
                        <div class="theme-card">
                            <ValidationObserver v-slot="{ invalid }">
                                <form class="theme-form" @submit.prevent="payAccess">
                                    <div class="form-group">
                                        <label for="dayCount">Nombre de jour d'acces</label>
                                        <ValidationProvider rules="required" v-slot="{ errors }" name="day">
                                            <input
                                                    type="number"
                                                    step="1"
                                                    :min="time_min_day"
                                                    class="form-control"
                                                    id="dayCount"
                                                    v-model="formDay.day"
                                                    placeholder="Nombre de jour"
                                                    name="day"
                                            />
                                            <span class="validate-error">{{ errors[0]?"SVP entrer identifiant ou email":"" }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group">
                                        <label>Prix unitaire / HTG</label>
                                        <div class="border rounded p-3">
                                            {{ access_time_price }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Total /HTG</label>
                                        <div class="border rounded p-3">
                                            {{ couttotal }}
                                        </div>

                                    </div>
                                    <button
                                            type="submit"
                                            class="btn btn-solid"
                                            :disabled="invalid"
                                    >Payer avec Moncash
                                    </button>
                                </form>
                            </ValidationObserver>
                        </div>
                    </div>
                    <div class="col-lg-6" v-else>
                        <h3>{{logintitle}}</h3>
                        <div class="theme-card">
                            <ValidationObserver v-slot="{ invalid }">
                                <form class="theme-form" @submit.prevent="onSubmit">
                                    <div class="form-group">
                                        <label for="email">Identifiant ou email</label>
                                        <ValidationProvider rules="required" v-slot="{ errors }" name="email">
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    id="email"
                                                    v-model="form.email"
                                                    placeholder="Identifiant ou email"
                                                    name="email"
                                            />
                                            <span class="validate-error">{{ errors[0]?"SVP entrer identifiant ou email":"" }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group">
                                        <label for="password">Mot de Passe</label>
                                        <ValidationProvider rules="required" v-slot="{ errors }" name="password">
                                            <input
                                                    type="password"
                                                    class="form-control"
                                                    id="password"
                                                    v-model="form.password"
                                                    placeholder="Entrer votre Mot de Passe"
                                            />
                                            <span class="validate-error">{{ errors[0]?"SVP entrer le mot de passe":"" }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <button
                                            type="submit"
                                            class="btn btn-solid"
                                            :disabled="invalid"
                                    >Se Connecter
                                    </button>
                                </form>
                            </ValidationObserver>
                            <div class="mt-3">
                                <router-link :to="{ path: '/forgot-password'}">
                                    <span>Mot de Passe oublié?</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 right-login">
                        <h3>{{registertitle}}</h3>
                        <div class="theme-card authentication-right">
                            <h6 class="title-font">Créer un Compte</h6>
                            <p>{{desc}}</p>
                            <router-link
                                    :to="{ path: '/register'}"
                                    class="btn btn-solid"
                            >Créer un Compte
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </div>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate/dist/vee-validate.full.esm";
  import Header from "../../../components/header/appHeader";
  import Footer from "../../../components/footer/appFooter";
  import Breadcrumbs from "../../../components/widgets/breadcrumbs";
  import {mapActions, mapGetters} from "vuex";

  import _ from 'lodash'

  export default {
    name: "login",
    components: {
      Header,
      Footer,
      Breadcrumbs,
      ValidationProvider,
      ValidationObserver
    },
    data() {
      return {
        logintitle: "Se Connecter",
        registertitle: "Êtes-vous nouveau?",
        desc: "Inscrivez-vous et devenez un agent promoteur indépendant (API) affilié à Ascension Group. En créant ce compte vous pouvez facilement effectuer vos achats, construire votre propre réseau de promotion et de vente et surtout gagner beaucoup d'argents.",
        form: {
          email: null,
          password: null
        },

        access_time_price: 0,
        access_time_min_day: 0,

        formDay: {
          id: "",
          pay_object: "TEMPS_ACCES",
          day: 30,
          payment: "Moncash"
        },

        needPayment: false
      };
    },
    methods: {
      ...mapActions({
        authenticateCustomer: "auth/login",
        pay: "payment/pay"
        // getCustomerCart: 'cart/fetchCart'
      }),

      async payAccess() {
        let loader = this.$loading.show();
        const payment = await this.pay(this.formDay);
        // await this.$store.dispatch("auth/payment", isLogged.data.user.customer_id);
        console.log(payment);
        if (payment.success) {
          this.needPayment = false;
          this.formDay = {
            id: "",
            pay_object: "TEMPS_ACCES",
            day: 30,
            payment: "Moncash"
          }
          loader.hide();
          // window.open(payment.data.link, '_blank')
          window.location.href = payment.data.link;
        } else {
          loader.hide();
          this.$swal("Oops", payment.message, "error");
        }
      },

      async onSubmit() {
        let loader = this.$loading.show();
        const isLogged = await this.authenticateCustomer(this.form);
        if (isLogged.success && isLogged.data.user.active && _.includes(['granted', 'denied', 'inactive'], isLogged.data.user.situation)) {
          loader.hide();
          this.$swal("Bienvenue", isLogged.message, isLogged.data.type);
          this.$router.push({ path: "/dashboard" });
        } else if (
          (isLogged.success && !isLogged.data.user.valide && isLogged.data.user.situation === "pending") ||
          (isLogged.success && isLogged.data.user.valide && isLogged.data.user.situation === "blocked")
        ) {
          loader.hide();
          this.$swal({
            // title: '<strong>Validation du compte</strong>',
            // icon: 'info',
            html: isLogged.message,
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: "<i class=\"fa fa-credit-card\"></i> Payer",
            confirmButtonColor: "red",
            cancelButtonText: "Quitter"
          }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.access_time_price = parseFloat(isLogged.data.access_price);
              this.formDay.id = isLogged.data.user.id;
              this.needPayment = true;
            }
          });
        } else {
          loader.hide();
          this.$swal("Oops", isLogged.message, "error");
        }
      }
    },

    computed: {
      ...mapGetters("setting", [
        "time_price",
        "time_min_day"
      ]),

      couttotal() {
        return (this.formDay.day * this.time_price).toFixed(2);
      }
    }
  };
</script>
